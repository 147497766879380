<template>
  <div id="app">
    <Nav />
    <div class="mt-50"></div>
    <div class="app-hz"></div>
    <div class="america-top-ul flex">
      <div
        class="america-top-li"
        v-for="top in touringTop"
        :key="top.id"
        @click="topData(top)"
        :class="type == top.id ? 'active' : ''"
      >
        <!-- <div class="top-li-img"></div> -->
        <img class="top-li-img" :src="top.imgUrl" alt="" />
        <div class="top-li-bot flex center">
          {{ $i18n.locale == "zh" ? top.title : top.title2 }}
        </div>
      </div>
    </div>
    <swiper ref="mySwiper" id="homeBanner" :options="bannerOptions">
      <swiper-slide v-for="(img, index) in focuslist" :key="index">
        <img :src="img.thumb" />
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    <div class="america-hz">
      <div v-if="$i18n.locale == 'zh'">
        <p class="america-p1">2018 年</p>
        <p class="america-p2">美国波特兰文化交流展演</p>
      </div>
      <div v-else>
        <p class="america-p7">America tour 2018</p>
      </div>
      <div class="america-across1"></div>
      <div class="america-across2"></div>
      <div class="america-details">
        <img
          class="america-details-img"
          src="../../../assets/tour/tour-8.png"
          alt=""
        />
        <span class="america-p3" v-if="$i18n.locale == 'zh'">
          美国时间2018年7月27日、7月28日晚，成立仅半年多的苏州民族管弦乐团第一次走出国门，在美国波特兰州立大学林肯音乐厅奏响首场《华乐苏韵》音乐会，而这也是林肯音乐厅首次迎来中国的民族管弦乐团。这一夜，展示中华民族文化的乐符在音乐大厅上空久久回荡；这一夜，音乐成为中西文化交流、增进双方和平友谊最好的桥梁；这一夜，苏州向世界亮出了靓丽的文化新名片，更让世界感受到了中华文化的魅力，尽显民族的文化自信。
        </span>
        <span class="america-p3" v-else>
          On the evening of July 27 and 28, 2018, the Suzhou Chinese Orchestra,
          which was established for just over half a year, went abroad for the
          first time and performed the "Charms of Chinese Music" in the Lincoln
          Hall of Portland State University in the United States. This was also
          the first time that Lincoln Hall welcomed a Chinese orchestra. On this
          night, musical notes showing the Chinese culture reverberated over the
          music hall for a long time; this night, music became the best bridge
          for cultural exchanges between China and the West and the promotion of
          peace and friendship between the two sides; this night, Suzhou showed
          a beautiful new cultural business card to the world. It also let the
          world feel the charm of Chinese culture and showed the cultural
          self-confidence of the nation.
        </span>
      </div>
      <p class="mt-10" v-if="$i18n.locale == 'zh'">
        <span class="america-p3">
          2018年是苏州市与美国波特兰市结为友好城市30周年，一曲热烈欢腾的《庆典序曲》，锣鼓、唢呐齐鸣，为美国观众开启了东方音乐之旅的大门。本次演出选取的六支曲目都是乐团经典保留曲目，在中西方文化的激烈碰撞中，拉近了美国观众与中国传统文化间的距离。“民族+管弦是对民族音乐的传承与创新，也是对国际文化的尊重与接轨。”乐团指挥彭家鹏说，“这次音乐会不仅将这支代表苏州、代表中国文化和地域文化的年轻乐团介绍给了波特兰观众，更将中华民族的音乐之美展现给美国观众。”
        </span>
      </p>
      <p class="mt-10" v-else>
        <span class="america-p3">
          2018 was the 30th anniversary of the sister city friendship between
          Suzhou and Portland. An enthusiastic and jubilant song "Celebration
          Overture", with gongs, drums and suona, opened the door for American
          audiences to a journey of Eastern music. The six repertoires selected
          for this performance were all classic repertoires of the orchestra. In
          the collision of Chinese and Western cultures, the distance between
          the American audience and the traditional Chinese culture has been
          narrowed. "National + Orchestra is the inheritance and innovation of
          national music, but also the respect and integration of international
          culture." Orchestra conductor Pang Kapang said, "This concert not only
          introduces this young orchestra that represents Suzhou, Chinese
          culture and regional culture to the Portland audience, but also also
          shows the beauty of the Chinese music to the American audience.”
        </span>
      </p>
      <div class="america-title">
        <div class="america-title-left">
          {{ $i18n.locale == "zh" ? "曲目" : "Program" }}
        </div>
      </div>
      <div v-if="$i18n.locale == 'zh'">
        <div class="america-list" v-for="item1 in chapterList1" :key="item1.id">
          <p class="america-p4">{{ item1.title }}</p>
          <p class="america-p5">{{ item1.name }}</p>
        </div>
        <div class="america-list">
          <p class="america-p4">——中场休息——</p>
          <img
            class="america-list-img"
            src="../../../assets/tour/tour-11.png"
            alt=""
          />
        </div>
        <div class="america-list" v-for="item2 in chapterList2" :key="item2.id">
          <p class="america-p4">{{ item2.title }}</p>
          <p class="america-p5">{{ item2.name }}</p>
        </div>
        <div class="america-list">
          <p class="america-p4">指挥：彭家鹏</p>
          <p class="america-p4">演奏：苏州民族管弦乐团</p>
        </div>
      </div>
      <div v-else>
        <div class="america-list" v-for="item3 in chapterList3" :key="item3.id">
          <p class="america-p4">{{ item3.title }}</p>
          <p class="america-p5">{{ item3.name }}</p>
        </div>
        <div class="america-list">
          <p class="america-p4">——Intermission——</p>
        </div>
        <div class="america-list" v-for="item4 in chapterList4" :key="item4.id">
          <p class="america-p4">{{ item4.title }}</p>
          <p class="america-p5">{{ item4.name }}</p>
        </div>
        <div class="america-list">
          <p class="america-p4">Conductor: Pang Kapang</p>
          <p class="america-p4">Performed by Suzhou Chinese Orchestra</p>
        </div>
      </div>
      <div class="america-title">
        <div class="america-title-left">
          {{ $i18n.locale == "zh" ? "相关报道" : "Press" }}
        </div>
        <!-- <img
          class="america-title-right"
          src="../../../assets/tour/tour-10.png"
          alt=""
        /> -->
      </div>
      <div class="america-report flex between">
        <div class="america-report-li">
          <img
            class="report-li-img"
            src="../../../assets/tour/tour-12.png"
            alt=""
          />
          <div class="report-li-hz flex v-center">
            <img
              class="report-hz-img1"
              src="../../../assets/tour/tour-14.png"
              alt=""
            />
            <img
              class="report-hz-img2"
              src="../../../assets/tour/tour-15.png"
              alt=""
            />
            <p class="america-p6" v-if="$i18n.locale == 'zh'">
              2018年9月3日《参考消息》 中国民族音乐倾倒美国乐迷
            </p>
            <p class="america-p8" v-else>
              September 3, 2018 "Reference News" Chinese folk music captivates American fans
            </p>
          </div>
        </div>
        <div class="america-report-li">
          <img
            class="report-li-img"
            src="../../../assets/tour/tour-13.png"
            alt=""
          />
          <div class="report-li-hz flex center">
            <img
              class="report-hz-img1"
              src="../../../assets/tour/tour-14.png"
              alt=""
            />
            <img
              class="report-hz-img2"
              src="../../../assets/tour/tour-15.png"
              alt=""
            />
            <p class="america-p6" v-if="$i18n.locale == 'zh'">中国日报</p>
            <p class="america-p6" v-else>China Daily REPORT</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 视频 -->
    <div class="videoBox">
      <video
        class="video"
        controls
        poster="@/assets/tour/tour-16.png"
        src="https://suco.oss-cn-hangzhou.aliyuncs.com/home/web/002.mp4"
      >
        your browser does not support the video tag
      </video>
    </div>
    <Footer />
  </div>
</template>

<script>
import Nav from "@/components/nav.vue";
import Footer from "@/components/footer.vue";

export default {
  name: "App",
  components: {
    Nav,
    Footer,
  },
  data() {
    return {
      touringTop: [
        {
          id: 0,
          title: "欧洲巡演",
          title2: "Europe Tour 2019",
          imgUrl: require("@/assets/tour/tour-60.png"),
          pageurl: "",
        },
        {
          id: 1,
          title: "韩国巡演",
          title2: "South Korea Tour 2019",
          imgUrl: require("@/assets/tour/tour-61.png"),
          pageurl: "",
        },
        {
          id: 2,
          title: "日本巡演",
          title2: "Japan Tour 2019",
          imgUrl: require("@/assets/tour/tour-62.png"),
          pageurl: "",
        },
        {
          id: 3,
          title: "美国巡演",
          title2: "America Tour 2018",
          imgUrl: require("@/assets/tour/tour-63.png"),
          pageurl: "",
        },
        {
          id: 4,
          title: "2023年 欧洲巡演",
          title2: "Europe Tour 2023",
          imgUrl: require("@/assets/tour/tour-64.png"),
          pageurl: "https://www.suco-europe-tour.com/",
        },
        {
          id: 5,
          title: "2024年 日本巡演",
          title2: "Europe Tour 2024",
          imgUrl: require("@/assets/tour/tour-65.png"),
          pageurl: "",
        }
      ],
      bannerOptions: {
        autoHeight: true,
        pagination: {
          el: "#homeBanner .swiper-pagination",
          clickable: true,
        },
      },
      focuslist: [
        {
          thumb: require("../../../assets/tour/tour-1.png"),
        },
        {
          thumb: require("../../../assets/tour/tour-2.png"),
        },
        {
          thumb: require("../../../assets/tour/tour-3.png"),
        },
        {
          thumb: require("../../../assets/tour/tour-4.png"),
        },
        {
          thumb: require("../../../assets/tour/tour-5.png"),
        },
        {
          thumb: require("../../../assets/tour/tour-6.png"),
        },
        {
          thumb: require("../../../assets/tour/tour-7.png"),
        },
      ],
      chapterList1: [
        {
          id: 0,
          title: "一.《庆典序曲》",
          name: "赵季平 曲",
        },
        {
          id: 1,
          title: "二.《肯塔基的故乡与苏珊娜》",
          name: "美国民歌 彭修文 改编",
        },
        {
          id: 2,
          title: "三. 《庄周梦》",
          name: "赵季平曲 大提琴：康乔瑄（特邀）",
        },
      ],
      chapterList2: [
        {
          id: 3,
          title: " 四. 《坎地德序曲》",
          name: "伯恩斯坦 曲 张大森改编",
        },
        {
          id: 4,
          title: "五．昆曲与民族管弦乐《牡丹亭·游园》",
          name: "朱昌耀 编曲 演唱：王芳（特邀）",
        },
        {
          id: 5,
          title: "六．《抒情变奏曲》",
          name: "刘长远曲",
        },
        {
          id: 6,
          title: "七.《澳门随想曲》",
          name: "王丹红曲",
        },
      ],
      chapterList3: [
        {
          id: 7,
          title: "1.「Festival Overture」",
          name: "Composed by Zhao Jiping",
        },
        {
          id: 8,
          title: "2.「Kentucky Home and Susanna」",
          name: "American Folk Song, Adapted by Peng Xiuwen",
        },
        {
          id: 9,
          title: "3.「Cello Concerto Butterfly Dream」",
          name: "Composed by Zhao Jiping Cello Soloist: Chiao-Hsuan Kang (Guest)",
        },
      ],
      chapterList4: [
        {
          id: 10,
          title: "4. Candide Overture",
          name: "Composed by Leonard Bernstein Adapted by Zhang Dasen",
        },
        {
          id: 11,
          title:
            "5.「Peony Pavilion·strolling in the Garden」（Kunqu & Chinese Orchestra）",
          name: "Composed by Zhu Changyao Singer: Wang Fang (Guest)",
        },
        {
          id: 12,
          title: "6.Lyrical Variations",
          name: "Composed by Liu Changyuan",
        },
        {
          id: 13,
          title: "7. Macau Capriccio",
          name: "Composed by Wang Danhong",
        },
      ],
      type: 3,
    };
  },
  computed: {},
  created: function () {
    // this.getInitData();
  },
  mounted() {},
  methods: {
    //  头部导航
    topData(val) {
       if (val.id != 3) {
        if (val.id == 4) {
          this.$router.push({ name: "Europe" });
        }else if (val.id == "5") {
          this.$router.push({ name: "Japan" });
        } else {
          this.$router.push({ path: "othershow", query: { type: val.id } });
        }
      }
    },
  },
};
</script>



<style lang="scss" scoped>
.app-hz {
  width: 100%;
  height: 15px;
}
.america-top-ul {
  width: 100%;
  height: 90px;
  margin-bottom: 15px;
  padding-left: 16px;
  box-sizing: border-box;
  overflow-y: hidden;
  overflow-x: auto;
  .america-top-li {
    width: 88px;
    height: 90px;
    background: transparent;
    margin-right: 12px;
    flex-shrink: 0;
    .top-li-img {
      width: 100%;
      height: 50px;
      display: block;
    }
    .top-li-bot {
      width: 100%;
      height: 38px;
      padding: 0 6px;
      line-height: 16px;
      box-sizing: border-box;
      font-size: 13px;
      color: #666666;
      border: 1px solid #835c46;
      border-radius: 0 0 18px 0;
      text-align: center;
    }
  }
  .active {
    .top-li-bot {
      font-size: 13px;
      color: #fff;
      background-color: #835c46;
    }
  }
}
.america-hz {
  padding: 20px 16px 20px;
  .america-p1 {
    font-size: 24px;
    color: #444444;
    line-height: 26px;
  }
  .america-p2 {
    margin: 4px 0 6px;
    font-size: 18px;
    color: #634636;
    line-height: 26px;
  }
  .america-p3 {
    font-size: 11px;
    color: #444444;
    line-height: 16px;
    font-weight: 300;
  }
  .america-p4 {
    font-size: 12px;
    color: #333333;
    line-height: 18px;
    font-weight: 400;
  }
  .america-p5 {
    font-size: 12px;
    color: #333333;
    line-height: 18px;
    font-weight: lighter;
  }
  .america-p6 {
    font-size: 12px;
    color: #ffffff;
    font-weight: 400;
    //   text-align: center;
    line-height: 18px;
  }
  .america-p7 {
    margin: 4px 0 6px;
    font-size: 18px;
    color: #444444;
    line-height: 26px;
  }
   .america-p8 {
    font-size: 10px;
    color: #ffffff;
    font-weight: 400;
    //   text-align: center;
    line-height: 13px;
  }
  .america-across1 {
    width: 100%;
    height: 2px;
    background-color: #c8c2bd;
  }
  .america-across2 {
    margin-top: 2px;
    width: 100%;
    height: 1px;
    background-color: #c8c2bd;
  }
  .inline {
    display: inline-block;
  }
  .america-details {
    margin-top: 15px;
    .america-details-img {
      width: 151px;
      height: 214px;
      margin-right: 10px;
      float: left;
    }
  }
  .america-title {
    margin-top: 24px;
    .america-title-left {
      display: inline-block;
      min-width: 100px;
      height: 30px;
      line-height: 30px;
      padding-left: 11px;
      box-sizing: border-box;
      color: #634636;
      background: linear-gradient(
        90deg,
        #b49a71 0%,
        rgba(180, 154, 113, 0) 100%
      );
    }
    .america-title-right {
      width: 218px;
      height: 36px;
    }
  }
  .america-list {
    position: relative;
    margin: 13px 0;
    .america-list-img {
      width: 168px;
      height: 228px;
      position: absolute;
      top: -114px;
      right: -16px;
    }
  }
  .america-report {
    margin: 16px 0;
    .america-report-li {
      width: 163px;
      .report-li-img {
        width: 100%;
        height: 188px;
        vertical-align: top;
      }
      .report-li-hz {
        position: relative;
        width: 100%;
        height: 58px;
        background-color: #b49a71;
        border-bottom-left-radius: 20px;
        padding: 0 5px;
        box-sizing: border-box;
        .report-hz-img1 {
          width: 15px;
          height: 50px;
          position: absolute;
          top: 7px;
          left: 0;
        }
        .report-hz-img2 {
          width: 18px;
          height: 59px;
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
  }
}
.videoBox {
  width: 100%;
  height: 180px;
  margin-bottom: 46px;
  .video {
    width: 100%;
  }
}
</style>
